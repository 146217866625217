<template>
  <div
    v-show="isVisible"
    class="d-flex jc-space-between bg-form p-24"
  >
    <div class="w-46">
      <div class="bg-white br-5 b-all bc-form-darker overflow-hidden bs-light">
        <div class="d-flex align-center jc-space-between p-12">
          <ProfileDetails
            :node="connection.parentProfile"
            hide-checkbox="true"
          />
          <div>
            <a class="text-caption color-link color-link-lighter_hover lh-24 transition" :href="postUrl" target="_blank">Post link</a>
          </div>
        </div>
        <div
          v-if="image"
          class="b-top b-bottom bc-form-darker"
        >
          <Avatar
            class="h-264px"
            :src="image"
            classRelative="h-264px"
            classImage="h-80"
            object-fit="contain"
          />
        </div>
        <div
          v-if="text"
          class="p-16"
        >
          <div class="text-caption color-text-lighter mb-8">Post text</div>
          <div class="mb-12">
            <span class="text-base color-text">{{ shortText }}</span>
            &nbsp;
            <a
              v-if="text.length > limit"
              @click.prevent="limit = (text.length + 1)"
              class="text-link"
            >Show more</a>
          </div>
          <div
            v-if="date"
            class="d-flex align-center jc-space-between text-base"
          >
            <span>{{ date.hours }}</span>
            <span>{{ date.days }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white br-5 b-all bc-form-darker w-52 overflow-hidden bs-light">
      <div v-if="liked" class="d-flex align-center b-bottom bc-form-darker p-12">
        <div class="p-relative br-circle color-link w-32px h-32px bg-form">
          <Icon class="p-absolute abs-center" name="conn-like" />
        </div>
        <div class="menu-title color-text-darker ml-16">
          {{ childProfileName }} liked {{ sourceProfileName }}'s post
        </div>
      </div>

      <div v-if="reacted" class="d-flex align-center b-bottom bc-form-darker p-12">
        <div class="p-relative br-circle color-link w-32px h-32px bg-form">
          <Icon class="p-absolute abs-center" name="conn-like" />
        </div>
        <div class="menu-title color-text-darker ml-16">
          {{ childProfileName }} reacted {{ sourceProfileName }}'s post
        </div>
      </div>

      <div v-if="tagged" class="d-flex align-center b-bottom bc-form-darker p-12">
        <div class="p-relative br-circle color-link w-32px h-32px bg-form">
          <Icon class="p-absolute abs-center" name="conn-dog" />
        </div>
        <div class="menu-title color-text-darker ml-16">
          {{ childProfileName }} tagged with {{ sourceProfileName }}
        </div>
      </div>

      <div v-if="commented" class="d-flex align-center b-bottom bc-form-darker p-12">
        <div class="p-relative br-circle color-link w-32px h-32px bg-form">
          <Icon class="p-absolute abs-center" name="conn-comment" />
        </div>
        <div class="menu-title color-text-darker ml-16">{{ childProfileName }} commented {{ sourceProfileName }}'s post</div>
      </div>

      <div v-if="repost" class="d-flex align-center b-bottom bc-form-darker p-12">
        <div class="p-relative br-circle color-link w-32px h-32px bg-form">
          <Icon class="p-absolute abs-center" name="conn-repost" />
        </div>
        <div class="menu-title color-text-darker ml-16">
          {{ childProfileName }} repost {{ sourceProfileName }}'s post
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/app/Avatar';
import Icon from '@/components/app/Icon';
import ProfileDetails from '@/components/UI/ProfileDetails';
import { dateConstructor, getProfileName } from '@/utils/helpers';
import unraw from 'unraw';

export default {
  components: {
    Avatar,
    Icon,
    ProfileDetails
  },
  emits: ['getFilter'],
  props: ['connection', 'post', 'visible'],
  data () {
    return {
      limit: 50,
      filterSend: false,
      types: []
    };
  },
  mounted () {
    if (!this.filterSend) {
      const filterToCheck = [
        { title: 'like', value: 'Liked' },
        { title: 'reaction', value: 'Reacted' },
        { title: 'comment', value: 'Commented' },
        { title: 'repost', value: 'Reposted' },
        { title: 'with', value: 'Tagged' },
        { title: 'mention', value: 'Mentioned' }
      ];

      filterToCheck.forEach(filter => {
        if (this.relationExists(filter.title)) {
          this.$emit('getFilter', filter.value);
          this.filterSend = true;
          this.types.push(filter.value);
        }
      });
    }
  },
  computed: {
    isVisible () {
      if (Object.keys(this.visible)) {
        let showPost = false;
        this.types.forEach(key => {
          if (this.visible[key]?.checked) showPost = true;
        });
        return showPost;
      } else return false;
    },
    image () {
      if (this.connection?.typeid === 'maltego.instagram.profile') {
        return document.location.origin + '/proxy/cors/' + this.post?.fields?.image;
      }
      return this.post?.fields?.image;
    },
    reverse () {
      return this.post?.reverse || this.post?.fields?.reverse;
    },
    sourceProfileName () {
      const sourceProfile = (this.reverse) ? this.connection : this.connection.parentProfile;
      const name = getProfileName(sourceProfile);
      const username = sourceProfile?.fields?.username;
      return name || username;
    },
    childProfileName () {
      return this.connection?.fields?.child_name || this.connection?.childProfile?.fields?.name || '';
    },
    date () {
      const date = this.post?.fields?.date || null;
      if (date) return { hours: dateConstructor(date, '{hours}:{minutes}'), days: dateConstructor(date, '{days}.{months}.{years}') };
      else return null;
    },
    liked () {
      return this.relationExists('like');
    },
    reacted () {
      return this.relationExists('reaction');
    },
    commented () {
      return this.relationExists('comment');
    },
    repost () {
      return this.relationExists('repost');
    },
    tagged () {
      return this.relationExists('with');
    },
    mentioned () {
      return this.relationExists('mention');
    },
    text () {
      const text = this.post?.fields?.text;
      if (text) return unraw(text.replaceAll(/\\n|\\r/g, '<br>'));
      else return '';
    },
    shortText () {
      return this.text.slice(0, this.limit);
    },
    url () {
      return this.connection.fields.surl?.value || this.connection.fields.url?.value;
    },
    postUrl () {
      return this.post?.fields?.url || this.post?.fields?.surl;
    },
    socialNetworkIcon () {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (this.connection.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    name () {
      return getProfileName(this.connection);
    }
  },
  methods: {
    parseConnectionPosts (posts, type) {
      if (posts) {
        return JSON.parse(posts);
      }
      return (this.connection && this.connection[type]) || {};
    },
    relationExists (relationType) {
      const postID = this.post.id || this.post.value;
      for (const relation of this.connection?.relations || []) {
        if ((postID.toString() === relation.target) && relation.type === relationType) {
          return true;
        }
      }
      const photo = this.parseConnectionPosts(this.connection?.childProfile?.fields?.connection_photos, 'connection_photos')[postID];
      if (photo && photo[relationType]) {
        return true;
      }
      const relations = this.connection?.childProfile?.fields?.relations;
      if (relations) {
        for (const relation of JSON.parse(relations)) {
          if ((postID.toString() === relation.target) && relation.type === relationType) {
            return true;
          }
        }
      }
      const post = this.parseConnectionPosts(this.connection?.childProfile?.fields?.connection_posts, 'connection_posts')[postID];
      return !!(post && post[relationType]);
    }
  }
};
</script>
