<template>
  <div class="p-relative">
    <div v-show="preload" class="p-absolute left-0 right-0 abs-center-y opacity-10">
      <Skeleton />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';

export default {
  components: { Skeleton },
  props: ['preload']
};
</script>
