<template>
  <div
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    class="p-relative text-center transition py-24"
    :class="{
      'bg-form': hovered && !checkedValue,
      'bg-form-darker': !hovered && !checkedValue,
      'bg-link': checkedValue
    }"
  >
    <label :for="id" class="p-absolute top-0 left-0 right-0 bottom-0 cursor-pointer"></label>
    <Checkbox
      class="p-relative custom-checkbox"
      :id="id"
      value="test"
      v-model="checkedValue"
      :binary="true"
      @change="check"
    />
    <div
      class="text-caption transition mt-8"
      :class="{
        'color-text-lighter': !checkedValue,
        'color-white': checkedValue
      }"
      v-html="label"
    ></div>
  </div>
</template>

<script>
import Checkbox from 'primevue/checkbox';
import { mapMutations, mapGetters } from 'vuex';

export default {
  components: {
    Checkbox
  },
  props: {
    id: {
      default: 'filter-select-all'
    },
    label: {
      default: 'Select<br>all'
    },
    connection: {
      default: null
    }
  },
  watch: {
    getCheckedValues: {
      deep: true,
      immediate: true,
      handler () {
        if (this.connection) {
          if (this.getCheckedValues[this.connection.oid]) {
            this.checkedValue = true;
          } else {
            this.checkedValue = false;
          }
        }
      }
    }
  },
  data () {
    return {
      hovered: false,
      checkedValue: false
    };
  },
  computed: {
    ...mapGetters(['getCheckedValues'])
  },
  methods: {
    ...mapMutations(['ADD_OR_REMOVE_CHECKED_ITEM']),
    check () {
      if (this.connection) {
        this.ADD_OR_REMOVE_CHECKED_ITEM(this.connection);
      }
    }
  }
};
</script>
