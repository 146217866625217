<template>
  <div class="overflow-hidden br-5 mb-8">
    <div class="d-grid gtc-auto-70px">
      <div class="bg-form p-24">
        <div class="d-flex align-center jc-space-between mb-24">
          <div class="d-flex align-center">
            <div class="p-relative z-2 br-circle bg-white w-74px h-74px p-5">
              <Avatar
                :src="profileImage.child"
                class="br-circle w-64px h-64px overflow-hidden"
              />
            </div>
            <div class="br-circle bg-white w-74px h-74px p-5 ml--37 mr-20">
              <Avatar
                :src="profileImage.parent"
                class="br-circle w-64px h-64px overflow-hidden"
              />
            </div>
            <Icon class="mr-10" :name="socialNetworkIcon" />
            <div class="text-h3">{{ connection.fields.child_name }} & {{ connection.fields.parent_name }}</div>
          </div>
          <div class="text-link no-hover color-text-lighter">
            Connection score: {{ connection.weight }}
          </div>
        </div>
        <div class="d-flex flex-w-wrap">
          <div
            v-for="(type, index) in connectionTypes"
            :key="index"
            class="bttn-search static mr-8 mb-8"
          >
            {{ type }}
          </div>
        </div>
      </div>
      <PanelCheckbox
        :id="'connection' + connection.oid"
        :connection="connection"
        label="Select"
      />
    </div>

    <div
      v-if="connection.nodes && Object.keys(filter).length > 1"
      class="bg-white p-24"
    >
      <div class="d-flex align-center jc-space-between">
        <div class="text-h4 color-text-lighter">Event type</div>
        <div class="d-flex flex-w-wrap">
          <button
            v-for="button in Object.keys(filter)"
            :key="button"
            class="bttn-search ml-8 mb-8"
            :class="{ 'is-active': filter[button].checked }"
            @click.prevent="toggleEvent(button)"
          >
            {{ button }} ({{ filter[button].count }})
          </button>
          <button
            class="bttn-search ml-8 mb-8"
            :class="{
              'is-active': allEventsChecked
            }"
            @click.prevent="toggleAllEvents"
          >
            All ({{ connection.nodes.length }})
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex align-center jc-space-between bg-white px-24 py-12">
      <div class="d-flex align-center">
        <TagTooltip
          class="mr-26"
          :profiles="[connection.parentProfile, connection.childProfile, connection]"
          size="small"
          position-x="left"
        />
        <a
          @click="setOpenRightSidebar([connection.childProfile, connection.parentProfile])"
          class="d-flex align-center text-caption color-link color-link-lighter_hover transition mr-26"
        >
          <Icon name="document-text-small" />
          <span class="ml-6">Profile Details</span>
        </a>
        <!-- <a class="d-flex align-center text-caption color-link color-link-lighter_hover transition">
          <Icon name="document-text-small" />
          <span class="ml-6">Fast Report</span>
        </a> -->
      </div>
      <PreloadText :preload="loadPosts">
        <a
          v-if="!connection.nodes"
          @click.prevent="loadNodes"
          class="text-caption color-link color-link-lighter_hover transition"
        >Download posts</a>
        <a
          v-else
          @click.prevent="showNodes = !showNodes"
          class="text-caption color-link color-link-lighter_hover transition"
        >{{ showNodes ? 'Hide' : 'Show' }}</a>
      </PreloadText>
    </div>
    <div
      v-if="connection.nodes"
      v-show="showNodes"
      class="bg-form-darker br-bottom-left-5 br-bottom-right-5 p-1 custom-scrollbar"
      :class="{ 'max-height': !loadPost }"
    >
      <ConnectionPost
        v-for="node in connection.nodes"
        :key="node.oid"
        :connection="connection"
        :post="node"
        :visible="filter"
        @getFilter="getFilter"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/app/Avatar';
import Icon from '@/components/app/Icon';
import TagTooltip from '@/components/UI/TagTooltip';
import PanelCheckbox from '@/components/UI/PanelCheckbox';
import ConnectionPost from '@/components/connections/Post';
import PreloadText from '@/components/UI/preload/PreloadText';
import { getProfileAvatar } from '@/utils/helpers';
import { mapActions } from 'vuex';

export default {
  components: {
    Avatar,
    Icon,
    TagTooltip,
    PanelCheckbox,
    ConnectionPost,
    PreloadText
  },
  props: {
    connection: { default: {} },
    loadPost: { default: false }
  },
  data () {
    return {
      checked: false,
      tabShow: '',
      showNodes: true,
      filter: {},
      loadPosts: false
    };
  },
  watch: {
    connection: {
      deep: true,
      immediate: true,
      handler () {
        this.checked = this.connection.checked;
      }
    }
  },
  created () {
    if (this.loadPost) this.loadNodes();
  },
  computed: {
    parentFields () { return this.connection.parentProfile.fields || []; },
    childFields () { return this.connection.childProfile.fields || []; },
    profileImage () {
      return {
        parent: getProfileAvatar(this.connection.parentProfile),
        child: getProfileAvatar(this.connection.childProfile)
      };
    },
    connectionTypes () {
      return this.childFields.connection_types.split(',') || [];
    },
    socialNetworkIcon () {
      let socialNetwork = '';
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (this.connection.typeid.includes(socialNet)) {
          socialNetwork = 'person-card-' + socialNet.toLowerCase();
        }
      }
      return socialNetwork;
    },
    allEventsChecked () {
      let allChecked = true;
      Object.keys(this.filter).forEach(elem => {
        if (!this.filter[elem].checked) allChecked = false;
      });
      return allChecked;
    }
  },
  methods: {
    ...mapActions(['setOpenRightSidebar', 'setLoadNodes']),

    getFilter (val) {
      let countVal = 1;
      if (this.filter[val]?.count) countVal += 1;

      this.filter[val] = {
        count: countVal,
        checked: true
      };
    },
    async loadNodes () {
      this.loadPosts = true;
      await this.setLoadNodes(this.connection);
      this.loadPosts = false;
    },
    toggleEvent (val) {
      this.filter[val].checked = !this.filter[val].checked;
    },
    toggleAllEvents () {
      const checked = !this.allEventsChecked;
      Object.keys(this.filter).forEach(elem => { this.filter[elem].checked = checked; });
    }
  }
};
</script>

<style lang="scss" scoped>
  .max-height {
    max-height: calc(100vh - 120px);
  }
</style>
